import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

// import NextTopLoader from "nextjs-toploader";
import { DNA } from "react-loader-spinner";

import "bootstrap/scss/bootstrap.scss";
import "../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "../public/css/plugins/fontawesome.min.css";
import "../public/css/plugins/feather.css";
import "../public/css/plugins/odometer.css";
import "../public/css/plugins/animation.css";
import "../public/css/plugins/euclid-circulara.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
// ========= Plugins CSS END =========

import "../public/scss/styles.scss";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { SessionProvider } from "next-auth/react";
import { Toaster } from "react-hot-toast";

export default function App({ Component, pageProps, session }) {
  const [loading, setLoading] = useState(true);
  const [isShowWP, setIsShowWp] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      setIsShowWp(true);
    }, 1000);
  }, []);

  useEffect(() => {
    // Sayfa ilk yüklendiğinde yükleme ekranını göster
    document.body.style.overflow = "hidden";
    const timer = setTimeout(() => {
      setLoading(false); // 1.5 saniye sonra yükleme ekranını kaldır
      document.body.style.overflow = "auto";
    }, 1500);

    // Cleanup fonksiyonu
    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "auto";
    };
  }, []);

  const showFloatingWhatsApp = router.pathname !== "/[slug]";

  return (
    <>
      {/* <NextTopLoader
        color="#2f57ef"
        initialPosition={0.08}
        crawlSpeed={200}
        height={3}
        crawl={true}
        showSpinner={true}
        easing="ease"
        speed={200}
        shadow="0 0 10px #2f57ef,0 0 5px #2f57ef"
        zIndex={1600}
        showAtBottom={false}
      /> */}
      {showFloatingWhatsApp && (
        <FloatingWhatsApp
          phoneNumber="905359221253"
          accountName="BilGen Yazılım Akademi"
          avatar="/BilGen.jpg"
          statusMessage="Genellikle 5 dakika içinde yanıt verir"
          chatMessage={`Merhaba! 👋\n\nWeb sitemizi ziyaret ettiğiniz için teşekkür ederiz. Eğitim programlarımız ve hizmetlerimiz hakkında herhangi bir sorunuz varsa, lütfen bu WhatsApp hattı üzerinden bize ulaşın. Size en kısa sürede yanıt vermekten mutluluk duyarız.\n\nİyi günler dileriz 😊`}
          placeholder="Bir mesaj yazın.."
          className={`text-black opacity-0 ${isShowWP && "opacity-100"}`}
          chatboxHeight={"500px"}
          notification={true}
          allowClickAway={true}
          allowEsc={true}
          notificationDelay={10}
          style={{
            bottom: "200px !important",
          }}
        />
      )}

      {loading ? (
        <div className={`loadingContainer ${loading ? "visible" : "hidden"}`}>
          <img src="/images/logo/BilGen-ikon-yanda.png" alt="logo" />
          <h2>BilGen Yazılım Akademi</h2>
          <p>“Yazılımın Geleceği Burada Başlıyor: BilGen Yazılım Akademi!”</p>
          <DNA
            visible={true}
            height="150"
            width="150"
            ariaLabel="dna-loading"
          />
        </div>
      ) : null}

      <SessionProvider session={session}>
        <Toaster />
        <Component {...pageProps} />
      </SessionProvider>
    </>
  );
}
